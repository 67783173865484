<template>
  <div class="page-link-code-by-code">

    <h3>1. Отсканируйте штрихкод</h3>

    <InputSearch code="F1" placeholder="Штрихкод" @submit="checkCode" ref="inputCode"/>
    <Loader :state="checkCodeLoad"/>

    <table v-if="goodsFromCode && goodsFromCode.id">
      <tr>
        <th colspan="3">
          Товар уже есть
        </th>
      </tr>
      <tr>
        <td>
          <b>[{{goodsFromCode.code}}]</b> {{ goodsFromCode.name }}<br>
          <a href="#" @click.prevent="image_uri = goodsFromCode.photo">изображение</a>
        </td>
        <td><strong>{{ goodsFromCode.pack }}</strong></td>
        <td>
          <button @click="showRemove = true">❌</button>
        </td>
      </tr>
    </table>

    <template v-else-if="goodsFromCode === ''">
      <h3>2. Выберите товар</h3>

      <InputSearch code="F2" placeholder="Поиск по названию / коду" @submit="getData" ref="inputSearch"/>
      <Loader :state="searchLoad"/>

      <table v-if="!searchLoad && goodsList.length">
        <tr>
          <th>
            Наименование
          </th>
          <th>
            Кол.
          </th>
          <th></th>
        </tr>
        <template v-for="g in goodsList" :key="g.id">
          <tr v-for="b in g.barcodes" :key="b.barcode">
            <td>
              {{b.barcode}}<br><br>
              <b>[{{g.code}}]</b> {{ g.name }}
              <a href="#" @click.prevent="image_uri = g.photo">изображение</a>
            </td>
            <td><strong>{{ b.amount }}</strong></td>
            <td>
              <button @click="goods = g; showConfirm = true">✅</button>
            </td>
          </tr>
        </template>
      </table>
      <EmptyData v-else-if="!searchLoad"/>

    </template>

    <ModalImage :uri="image_uri" @close="image_uri = ''"/>

    <ModalConfirm :show="showConfirm" :status="status" @confirm="confirm" @cancel="cancel">
      <template #title><h3>Названить код {{code}} ?</h3></template>
      <template #body>
        <p v-if="goods">{{ goods.name }}</p>
        <div class="flex">
          <div>
            <button type="button" @click="change(-1)">-</button>
            <input type="number" v-model="amount" min="1" step="1">
            <button type="button" @click="change(1)">+</button>
          </div>
          <small>Введите кол-во, если это коробка с несколькими товарами</small>
        </div>
      </template>
    </ModalConfirm>

    <ModalConfirm :show="showRemove" :status="statusRemove" @confirm="confirmRemove" @cancel="cancel">
      <template #title><h3>Удалить привязку штрихкода?</h3></template>
      <template #body>
        <br>
      </template>
    </ModalConfirm>

  </div>
</template>

<script>
import {ref, onMounted, onUnmounted} from 'vue'
import ModalConfirm from "@/components/ModalConfirm";
import InputSearch from "@/components/InputSearch";
import Loader from "@/components/Loader";
import EmptyData from "@/components/EmptyData";
import ModalImage from "@/components/ModalImage";

export default {
  components: {ModalImage, EmptyData, Loader, InputSearch, ModalConfirm},
  setup(props, {emit}) {
    emit('meta', {title: 'Привязка по коду', back: '/link_code'})

    const inputCode = ref(null)
    onMounted(() => {
      inputCode.value.input.focus()
    })

    let checkCodeLoad = ref('')
    let goodsFromCode = ref(null)
    const inputSearch = ref(null)
    let code = ref('')
    const checkCode = async (params) => {
      checkCodeLoad.value = 'проверка кода...'
      goodsFromCode.value = null
      code.value = params.search

      if (params.search) {
        const data = await api.getGoodsFind(params.search)
        goodsFromCode.value = data
        if (data === '') setTimeout(() => {
          inputSearch.value.input.focus()
        })
      }

      checkCodeLoad.value = ''
    }

    let goodsList = ref([])
    let searchLoad = ref('')
    let image_uri = ref('')
    const getData = async (params) => {
      // params.codeEmpty = true
      goodsList.value = []

      if(params.search) {
        searchLoad.value = 'поиск товара...'
        let data = await api.getGoods(params)
        goodsList.value = data.data
        searchLoad.value = ''
      }
    }

    let amount = ref(1)
    const change = (val) => {
      try {
        navigator.vibrate(100);
      } catch (er) {}
      amount.value += val
      if(amount.value < 1) amount.value = 1
    }

    let showConfirm = ref(false)
    let goods = ref(null)
    let status = ref('')
    const confirm = async () => {
      let params = {
        id: goods.value.id,
        code: code.value,
        amount: amount.value
      }
      status.value = 'В процессе...'
      status.value = await api.setGoods(params)
    }

    let showRemove = ref(false)
    let statusRemove = ref('')
    const confirmRemove = async () => {
      let params = {
        id: goodsFromCode.value.id,
        code: code.value,
      }
      statusRemove.value = 'В процессе...'
      statusRemove.value = await api.removeGoods(params)
    }

    const cancel = () => {
      showConfirm.value = false
      showRemove.value = false
      if (status.value === 'success' || statusRemove.value === 'success') {
        status.value = ''
        statusRemove.value = ''
        amount.value = 1
        goodsList.value = []
        goodsFromCode.value = null
        inputCode.value.input.value = ''
        inputCode.value.input.focus()
      }
    }

    const keydown = (event) => {
      if (event.code === 'F13' && inputCode.value.input !== document.activeElement) {
        inputCode.value.input.value = ''
        inputCode.value.input.focus()
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', keydown)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', keydown)
    })

    return {
      inputCode, code, checkCodeLoad, checkCode, goodsFromCode,
      goods, amount, change, inputSearch, status, goodsList, getData, searchLoad,
      showConfirm, confirm, cancel, image_uri, showRemove, statusRemove, confirmRemove
    }
  }
}
</script>

<style lang="less" scoped>
h3 {
  font-size: 18px;
  text-align: center;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input {
    width: 80px;
    text-align: center;
    margin: 0 10px;
  }

  small {
    margin: 10px 0;
  }
}
</style>
